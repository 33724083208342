// nuxt
import { useNuxtApp } from '#app'

// types
import type {
  Nullable,
  Student
} from '@revolutionprep/types'

// vue
import { computed, ref } from 'vue'

// utilities
import { isAfter, isBefore, isEqual } from 'date-fns'

export function useCalendarPusher () {
  /**
   * nuxt app
   * ==================================================================
   */
  const { $actor } = useNuxtApp()

  /**
   * state
   * ==================================================================
   */
  const currentCalendarEnd = ref('')
  const currentCalendarStart = ref('')

  /**
   * computed
   * ==================================================================
   */
  const actor = computed(() => {
    return $actor.core.actor.value as Nullable<Student>
  })

  const privateChannel = computed(() => {
    return `private-revolution-user-${actor.value?.userId}`
  })

  /**
   * methods
   * ==================================================================
   */
  function doIntervalChange (
    { startISO, endISO }: { [key: string]: string }
  ) {
    currentCalendarStart.value = startISO
    currentCalendarEnd.value = endISO
  }

  function isAfterOrEqual (dateA: Date, dateB: Date) {
    return isAfter(dateA, dateB) || isEqual(dateA, dateB)
  }

  function isBeforeOrEqual (dateA: Date, dateB: Date) {
    return isBefore(dateA, dateB) || isEqual(dateA, dateB)
  }

  function isVisible (startsAt: string, endsAt: string) {
    const start = new Date(startsAt)
    const end = new Date(endsAt)
    const calendarStart = new Date(currentCalendarStart.value)
    const calendarEnd = new Date(currentCalendarEnd.value)
    // calendar start is before or equal to startsAt
    const startIsVisible = isBeforeOrEqual(calendarStart, start)
    // calendarEnd is after or equal to endsAt
    const endIsVisible = isAfterOrEqual(calendarEnd, end)
    return startIsVisible && endIsVisible
  }

  return {
    currentCalendarEnd,
    currentCalendarStart,
    doIntervalChange,
    isAfterOrEqual,
    isBeforeOrEqual,
    isVisible,
    privateChannel
  }
}
